<section class="segments container container-fhd">
  <div class="segments__title">
    <h2 [innerHTML]="title | translate"></h2>
  </div>

  @if(!isLgScreen) {
  <div class="row g-3">
    @for (segment of data; track $index) {
    <div [ngClass]="['col col-sm-6 col-lg-3', gridItemClass]">
      <qsc-segment-card [data]="segment"></qsc-segment-card>
    </div>
    }
  </div>
  } @else {
  <swiper [config]="swiperConfig">
    @for (segment of data; track $index) {
    <ng-template swiperSlide>
      <qsc-segment-card [data]="segment"></qsc-segment-card>
    </ng-template>
    }
  </swiper>
  }
</section>
